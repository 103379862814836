.App {
  .project-wrapper {
    position: relative;
    display: block;
  }
  .project {
    margin-bottom: 1em;
    @media only screen and (max-width: 767px) {
      margin-bottom: 2em;
    }
    .segment {
      .image-wrapper {
        display: block;
        position: relative;
        background-color: #eee;
        cursor: pointer;
        img {
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .arrows-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .arrows {
          display: inline;
          cursor: pointer;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
        }
        .next {
          float: right;
        }
        .prev.arrows {
          padding: 0.3em 2em 0.3em 0.2em;
          @media only screen and (max-width: 768px) {
            padding: 0.3em 2em 0.3em 0.6em;
          }
        }
        .next.arrows {
          padding: 0.3em 0.2em 0.3em 2em;
          @media only screen and (max-width: 768px) {
            padding: 0.3em 0.6em 0.3em 2em;
          }
        }
        .image-counter {
          font-size: 0.7em;
          display: inline-block;
          margin: auto;
          text-align: center;
        }
      }
      h3 {
        padding: 0 0.3em;
        font-family: 'unit-medium', 'Helevetica', sans-serif;
        line-height: 1.2em;
        margin-top: 0.6em;
        margin-bottom: 0.5em;
      }
      img.closer {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999999;
        padding: 0.2em;
        @media only screen and (max-width: 767px) {
          padding: 0;
        }
      }
      .content {
        padding: 0 0.3em;
        p {
          strong,
          b {
            font-family: 'unit-medium', 'Helevetica', sans-serif;
            font-weight: normal;
          }
        }
      }
    }
    .segment:hover {
      img {
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
      }
    }
  }
  .project.expanded,
  .project.expanding {
    @media only screen and (max-width: 767px) {
      margin-bottom: 3.4em;
    }
    .segment {
      font-size: 120%;
      img {
        -webkit-filter: unset; /* Safari 6.0 - 9.0 */
        filter: unset;
      }
      h3 {
        font-size: 140%;
        @media only screen and (max-width: 768px) {
          padding: 0 0.6em;
        }
      }
      .content {
        @media only screen and (max-width: 768px) {
          padding: 0 0.8em;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .ui.stackable.grid .project-wrapper.ten.wide.column {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .ui.stackable.grid > .column.project-wrapper.odd {
    padding-left: 0 !important;
  }
  .ui.stackable.grid > .column.project-wrapper.even {
    padding-right: 0 !important;
  }

  /* BASIC ZOOM ANIMATION */
  .column {
    -webkit-transition: width 0.25s, height 0.25s;
    -moz-transition: width 0.25s, height 0.25s;
    -o-transition: width 0.25s, height 0.25s;
    transition: width 0.25s, height 0.25s;
  }

  /* REARRANGING ANIMATIONS FOR THE GRID -> SEE JS ALSO */
  .ui.stackable.grid > .column.project-wrapper.grow-one-column {
    -webkit-transition: margin-left 0.25s;
    -moz-transition: margin-left 0.25s;
    -o-transition: margin-left 0.25s;
    transition: margin-left 0.25s;
    animation-name: one-column;
    animation-duration: 0.25s;
  }
  @keyframes one-column {
    0% {
      margin-left: 50%;
    }

    100% {
      margin-left: 0%;
    }
  }

  .ui.stackable.grid > .column.project-wrapper.grow-middle-column {
    -webkit-transition: margin-left 0.25s;
    -moz-transition: margin-left 0.25s;
    -o-transition: margin-left 0.25s;
    transition: margin-left 0.25s;
    transition: margin-right 0.25s;
    animation-name: middle-column;
    animation-duration: 0.25s;
    //border: 1px solid blue;
  }
  @keyframes middle-column {
    0% {
      margin-left: 33%;
      margin-right: 33%;
    }

    100% {
      margin-left: 0%;
      margin-right: 0%;
    }
  }

  .ui.stackable.grid > .column.project-wrapper.grow-two-a-column {
    -webkit-transition: margin-left 0.125s;
    -moz-transition: margin-left 0.125s;
    -o-transition: margin-left 0.125s;
    transition: margin-left 0.125s;
    animation-name: two-a-column;
    animation-duration: 0.125s;
    //border: 1px solid blue;
  }
  @keyframes two-a-column {
    0% {
      margin-left: 33%;
    }

    100% {
      margin-left: 0%;
    }
  }

  .ui.stackable.grid > .column.project-wrapper.grow-two-b-column {
    -webkit-transition: margin-left 0.125s;
    -moz-transition: margin-left 0.125s;
    -o-transition: margin-left 0.125s;
    transition: margin-left 0.125s;
    animation-name: two-a-column;
    animation-duration: 0.125s;
    //border: 1px solid blue;
  }
  @keyframes two-b-column {
    0% {
      margin-left: 33%;
    }

    100% {
      margin-left: 0%;
    }
  }

  .ui.stackable.grid > .column.project-wrapper.grow-bigger-column {
    -webkit-transition: margin-left 0.25s;
    -moz-transition: margin-left 0.25s;
    -o-transition: margin-left 0.25s;
    transition: margin-left 0.25s;
    animation-name: one-column;
    animation-duration: 0.25s;
  }
  @keyframes one-column {
    0% {
      margin-left: 33%;
    }

    100% {
      margin-left: 0%;
    }
  }
}
