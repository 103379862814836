@font-face {
  font-family: 'unit-medium';
  src: url('fonts/rieterunitotmedium-webfont.eot');
  src: url('fonts/rieterunitotmedium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/rieterunitotmedium-webfont.woff') format('woff'),
    url('fonts/rieterunitotmedium-webfont.ttf') format('truetype'),
    url('fonts/rieterunitotmedium-webfont.svg#unit-medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'unit-light';
  src: url('fonts/rieterunitotlight-webfont.eot');
  src: url('fonts/rieterunitotlight-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/rieterunitotlight-webfont.woff') format('woff'),
    url('fonts/rieterunitotlight-webfont.ttf') format('truetype'),
    url('fonts/rieterunitotlight-webfont.svg#unit-light') format('svg');
  font-weight: normal;
  font-style: normal;
}

.App {
  max-width: 820px;
  margin: 0 auto;

  font-family: 'unit-light', 'Helvetica', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
    line-height: normal;
  }

  a {
    color: #9a781d;
  }

  a:hover {
    text-decoration: underline;
  }

  a.plain-link {
    color: unset;
  }
  a.plain-link:hover {
    text-decoration: unset;
  }

  .ui.segment {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  // GRID BEHAVIOUR
  //make ten wide columns 2/3 of the grid width
  .ui.column.grid > [class*='ten wide'].column,
  .ui.grid > .column.row > [class*='ten wide'].column,
  .ui.grid > .row > [class*='ten wide'].column,
  .ui.grid > [class*='ten wide'].column {
    width: 66% !important;
  }

  //allways us two columns on smartphones
  @media only screen and (max-width: 767px) {
    .ui.grid > .stackable.stackable.row > .column,
    .ui.stackable.grid > .column.grid > .column,
    .ui.stackable.grid > .column.row > .column,
    .ui.stackable.grid > .column:not(.row),
    .ui.stackable.grid > .row > .column,
    .ui.stackable.grid > .row > .wide.column,
    .ui.stackable.grid > .wide.column {
      width: 50% !important;
      padding: 0.2em !important;
    }
  }

  //make ten wide columns use the whole grid width on smartphones
  @media only screen and (max-width: 767px) {
    .ui.column.grid > [class*='ten wide'].column,
    .ui.grid > .column.row > [class*='ten wide'].column,
    .ui.grid > .row > [class*='ten wide'].column,
    .ui.grid > [class*='ten wide'].column {
      width: 100% !important;
    }
  }
  // END GRID BEHAVIOUR
}
